import APP_CONST from '@/constants/AppConst';
import analyticsStore from '@/store/modules/analyticsStore';
import { Vue, Component } from 'vue-property-decorator';
import FamilyEnagementCard from '../../commonComponents/familyEnagementCard/FamilyEnagementCard.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
@Component({
  components: {
    'family-enagement-card' : FamilyEnagementCard,
    'bouncing-preloader': BouncingPreloaderComponent,
  }
})


export default class TeamTabFamilyEngagement extends Vue {

    public bxForTeams: string = APP_CONST.BX_FOR_TEAMS;
    public headingData : string =  APP_CONST.TOTAL_POSTS;
    public activeMobileUsers : string = APP_CONST.ACTIVE_MOBILE_USERS;
    public totalDirectMessagestoTeamMembers : string = APP_CONST.MESSAGES_TO_TEAM_MEMBERS;
    public totalDirectMessagestoGuardians : string = APP_CONST.MESSAGES_TO_Guardians;
    public totalPostsImage:string = APP_CONST.TOTAL_POSTS_IMAGE;
    public activeMobileUsersImage:string = APP_CONST.ACTIVE_MOBILE_USERS_IMAGE;
    public message_image:string = APP_CONST.MESSAGES_IMAGE;
    public noData:string = APP_CONST.BLANK_PHONE_NUMBER;
    public isLoaderVisible: boolean = false;

    get familyEnagementTeamData(){
      return analyticsStore.familyEnagementTeamDataObj;
    }

    beforeMount(){
      this.isLoaderVisible = true;
      const accountId:number = Number(APP_UTILITIES.getCookie('accountId'));
      analyticsStore.mutateFamilyEngagementTab('team');
      analyticsStore.fetchFamilyEnagementTeamData(accountId).then(()=>{
        this.isLoaderVisible = false;
      });

    }
}